<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-mypage" >

    <section class="content-wrap st-center">
      <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
        <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
        <div class="sub-tab-mb-title">
          <a href="javascript:;" class="on" @click="onClickTabMobile()" >내 게시글 <i class="arrow"></i></a> 
        </div>
        <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
          <a href="javascript:;"><router-link to="/my/myInfo">회원정보</router-link> </a>
          <a href="javascript:;"><router-link to="/my/meetList">모임 내역</router-link></a>
          <a href="javascript:;"><router-link to="/my/videoConference">화상 회의</router-link></a>
          <a href="javascript:;"><router-link to="/my/board">내 게시글</router-link></a>
          <a href="javascript:;"><router-link to="/my/message">쪽지함</router-link></a>
          <a href="javascript:;"><router-link to="/my/inquiry">1:1 문의</router-link></a>
          <a href="javascript:;" @click="logout">로그아웃</a>
        </div>
      </div>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">

          <div class="mypage-wrap">
            <div class="aside-box">
              <div class="people-info-box">
                <div class="img-box">
                  <img class="member-list-box" v-if="userDetail.photoFile==null" src="../../assets/front/images/icon/member.jpg"/>
                  <img class="member-list-box" v-else :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
                </div>
              <div class="name-box">
                <div class="name"><em>{{ userDetail.name }}</em><span>님</span></div>
                <div class="cate">{{ userDetailDepartment }} / {{ memberClub(userDetail.clubGroup) }}</div>
                </div>
                <div class="btn-box">
                  <router-link to="/my/message">쪽지함</router-link>
                  <router-link to="/my/inquiry">1:1 문의</router-link>
                </div>
              </div>
              <div class="menu-box">
                <ul>
                  <li><router-link to="/my/myInfo">회원정보</router-link></li>
                  <li class="on"><router-link to="/my/inquiry">활동 내역</router-link>
                    <ul>
                      <li><router-link to="/my/inquiry">1:1 문의</router-link></li>
                      <li class="on"><a href="javascript:;" @click="onClickMenu">내 게시글</a></li>
                      <li><router-link to="/my/message">쪽지함</router-link></li>
                    </ul>
                  </li>
                  <li><router-link to="/my/meetList">모임 내역</router-link></li>
                  <li><router-link to="/my/videoConference">화상 회의</router-link>
                    <ul>
                      <li><router-link to="/my/videoConference">전체 회의</router-link></li>
                      <li><router-link to="/my/myConference">내가 개설한 회의</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="main-body-box">
              <div class="my-tit-box">내 게시글</div>
              <div class="board-search-wrap st3">
                <div class="tit-box mb-right"><a href="javascript:;" @click="onClickDelete" class="btn-mod-2 ">선택 삭제</a></div>
                <div class="search-box">
                    <select v-model="searchColumn" class="input-st1 st-selectbox wd150" style="height: 40px;line-height: 38px;">
                      <option value="boardName">게시판</option>
                      <option value="title">제목</option>
                      <option value="contents">내용</option>
                    </select>
                    <input type="text" v-model="searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd280 dg-ml-5" style="height: 40px;line-height: 38px;" @keydown="onKeydown">
                    <a href="javascript:;" @click="onClickSearch" style="height: 40px;line-height: 38px;" class="btn-s dg-ml-5">검색</a>
                </div>
              </div>
              <div class="tb-01 st2 st-over mb50">
                <table>
                  <colgroup><col style="width:70px"><col style="width:70px"><col style="width:120px"><col style="width:*"><col style="width:120px"><col style="width:100px"></colgroup>
                  <thead>
                  <tr>
                    <th>
                      <input type="checkbox" class="chk-st1 st-none" id="chkall" v-model="checkAll" @change="onClickCheckAll"><label for="chkall">전체선택</label>
                    </th>
                    <th>번호</th>
                    <th>게시판</th>
                    <th>제목</th>
                    <th>작성일</th>
                    <th>조회수</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(content,idx) in contents.content" :key="content.articleId"  class="st-board">
                    <td class="al-center pc-only">
                      <input type="checkbox" v-model="selectCheckBox" :value="content" class="chk-st1 st-none" :id="'chk010'+idx" @change="onClickCheckBox"><label :for="'chk010'+idx">선택</label>
                    </td>
                    <td class="al-center pc-only">{{ pagination.totalElements - ((pagination.currentPage-1) * pagination.pageSize) - idx }}</td>
                    <td class="ellipsis_inline al-center  pc-only" style="max-width:120px;display: revert">{{ content.boardName }}</td>
                    <td class=" pc-only ">
                      <a href="javascript:;" @click="onClickTitle(content)" class="ellipsis_inline" style="max-width: 300px">{{ content.title }}</a>
                      <div class="tit-icon" style="padding-left: 7px">
                        <span class="cmt" v-if="content.commentCount > 0">[{{ content.commentCount }}]</span>
                      </div>
                    </td>
                    <td class="al-center pc-only">{{ formatDate(content.createdAt) }}</td>
                    <td class="al-center pc-only">{{ content.hits==null?0:content.hits }}</td>
                    <td class="mb-only">
                      <div class="tb-top">
                        <div class="tb-notice"><input type="checkbox" v-model="selectCheckBox" :value="content" class="chk-st1 st-none" :id="'chk010'+idx" @change="onClickCheckBox"><label :for="'chk010'+idx">선택</label></div>  
                        <div class="tb-title">
                          <a href="javascript:;" @click="onClickTitle(content)" class="ellipsis_inline" style="max-width: 300px">{{ content.title }}</a>
                          <div class="tit-icon" style="padding-left: 7px">
                            <span class="cmt" v-if="content.commentCount > 0">[{{ content.commentCount }}]</span>
                          </div> 
                        </div>
                      </div>
                      <div class="tb-btm">
                        <div class="send-msg-menu-box tb-name"  ><!-- open 클래스 추가 시 버튼 노출 -->
                          <a href="javascript:;" class="btnSendMenu">{{ content.boardName }}</a>
                        </div>
                        <div class="tb-hit">
                          <span>조회수</span>
                          <em>{{ content.hits==null?0:content.hits }}</em>
                        </div>
                        <div class="tb-date">
                          {{ formatDate(content.createdAt) }}
                        </div>
                        <div class="tb-cmt" v-if="content.commentCount > 0">
                          <span >댓글</span>
                          <em class="cmt" v-if="content.commentCount > 0">[{{ content.commentCount }}]</em>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- class : pagination (S) -->
              <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
              <!-- class : pagination (E) -->
            </div>
          </div>

        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import userApi from "@/api/UserApi";
import articleApi from "@/api/ArticleApi";
import moment from "moment";
import DgPagination from '@/views/component/DgPagination';
import boardApi from "@/api/BoardApi";
import menuApi from "@/api/MenuApi";

export default {
  name: "board_list",
  created(){
    if(this.userId == '') {
      alert('로그인 후 이용하실 수 있습니다.')
      this.$router.push(`/login`)
    }else{
      this.getUserDetail();
      this.onClickSearch();
    }
  },
  components:{
    DgPagination
  },
  data(){
    return {
      userId: this.$store.state.user.userid,
      userDetail: {},
      userDetailDepartment: '',

      contents: [],
      pagination:{
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },

      selectCheckBox:[],
      checkAll:false,

      searchColumn:'boardName',
      searchKeyword:'',
      tablistOpen : false,
    }
  },
  watch:{
    selectCheckBox(){
      let checkSize = this.pagination.totalElements < this.pagination.pageSize? this.pagination.totalElements : this.pagination.pageSize;
      if(this.selectCheckBox.length == checkSize  && checkSize != 0){
        this.checkAll = true
      }
    }
  },
  methods: {
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    getUserDetail(){
      userApi.get(this.userId).then((data) =>{
        this.userDetail = data
        this.userDetailDepartment = data.departmentGroup[0].groupName
      })
    },
    memberClub(val){
      if(val != null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }else{
        return '';
      }
    },
    onClickSearch(){
      let params = {
        size: this.pagination.pageSize,
        page: this.pagination.currentPage,
        exceptBoardIds: 1748, // 1:1 문의 게시판 boardId
        searchColumn: this.searchColumn,
        searchKeyword: this.searchKeyword
      };

      articleApi.getMyAllArticles(this.userId, params).then((data)=>{
//        console.log('???'+JSON.stringify(data))
        this.contents = data;

        this.pagination.totalElements=data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
      })
    },
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.checkAll=false;
      this.selectCheckBox=[];
      this.onClickSearch();
    },
    onClickTitle(content){
      boardApi.get(content.boardId).then((data)=> {
        let menuId = null;
        if (data.targetType == 'TARGET_DEPARTMENT') {
          menuId = 427;
        } else if (data.targetType == 'TARGET_CLUB') {
          menuId = 428;
        } else if (data.targetType == 'TARGET_GENERAL') {
          menuId = 431;
        }

        menuApi.getMenu(menuId).then(data=> {
          let tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
          let currentTabMenu = tabs.find(c => c.childMenus.find(d => d.boardId == content.boardId))
          this.$router.push(`/board/${content.boardId}?upper=${menuId}&menu=${currentTabMenu.menuId}&c=detail&at=${content.articleId}&fr=main`)
        })
      })
    },
    menuParse(m) {
      if(!m.activated) {
        return null;
      }

      if(m.menuType === 'TYPE_BOARD') {
        m.url = `/board/${m.boardId}`;
      }
      return {
        menu: m,
        id : m.menuId,
        protected : m.protected,
        title : m.menuName,
        icon : m.menuIcon,
        href : m.url??null,
        child : m?.childMenus?.length??0 > 0 ? m?.childMenus?.map(this.menuParse).filter(c => c !== null) : null
      }
    },
    onClickCheckAll(){
      if(this.checkAll){
        this.contents.content.forEach(data=>{
          this.selectCheckBox.push(data)
        })
      }else{
        this.selectCheckBox = []
      }
    },
    onClickCheckBox(){
      if(this.selectCheckBox.length == this.pagination.pageSize){
        this.checkAll=true
      }else{
        this.checkAll=false
      }
    },
    onClickDelete(){
      if(this.selectCheckBox.length==0){
        return alert('한 개 이상의 게시글을 선택해주세요.')
      }else{
        if(confirm('지금 삭제하시면 내용을 복구할 수 없습니다. \n삭제하시겠습니까?')) {
          this.selectCheckBox.forEach(data => {
            articleApi.delete(data.boardId, data.articleId).then(()=>{
              this.pagination.currentPage = 1;
              this.checkAll=false;
              this.selectCheckBox=[];
              this.onClickSearch();
            })
          })
        }
      }
    },
    onKeydown(e){
      if(e.keyCode===13){
        this.onClickSearch();
      }
    },
    onClickMenu(){
      this.contents= [],
      this.pagination= {
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages: 0,
        totalElements: 0
      },
      this.selectCheckBox=[],
      this.checkAll=false,

      this.searchColumn='boardName',
      this.searchKeyword=''

      this.onClickSearch();
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    },
    logout() {
      this.$store.dispatch('user/logout');
      window.location.href='/'
    },
  },
}
</script>

<style scoped>

</style>